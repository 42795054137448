import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, Title,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import MBMLabels from './MBMLabels.json';

const MBMCarousel = ({ data }) => {
  ChartJS.register(ArcElement, Tooltip, Legend, Title);

  const formatData = () => {
    const charts = [];
    let relevantKeys = [];
    let labels = [];
    let datapoints = [];
    let colors = [];
    let title = '';

    const allColors = [
      'rgba(220, 20, 60, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
      'rgba(255, 105, 180, 1)',
      'rgba(128, 0, 0, 1)',
      'rgba(50, 205, 50, 1)',
      'rgba(139, 0, 139, 1)',
    ];

    const keyFields = ['align', 'engage', 'age', 'income', 'race', 'gen', 'edu'];
    keyFields.forEach((field) => {
      title = MBMLabels[field];
      relevantKeys = Object.keys(data).filter((key) => key.includes(`_${field}_`));
      labels = relevantKeys.map((key) => MBMLabels[key]);
      datapoints = relevantKeys.map((key) => data[key] * 100);
      colors = allColors.slice(0, labels.length);
      charts.push({
        title,
        chartData: {
          labels,
          datasets: [{
            label: title,
            data: datapoints,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          }],
        },
      });
    });

    return charts;
  };

  const charts = formatData(data);

  return (
    <Carousel className="bg-dark mbm-carousel" variant="light" interval={null}>
      {charts.map((chart) => (
        <Carousel.Item key={chart.title} className="mx-auto p-5">
          <Pie
            data={chart.chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              color: '#f5f5f5',
              plugins: {
                title: {
                  display: true,
                  text: chart.title,
                  position: 'top',
                  padding: {
                    bottom: 20,
                  },
                  color: '#ffffff',
                  font: { weight: 'bold', size: 20 },
                },
                legend: {
                  display: true,
                  position: 'bottom',

                },
                tooltip: {
                  callbacks: {
                    label: ({ label, formattedValue }) => `${label}: ${formattedValue}%`,
                  },
                },
              },
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default MBMCarousel;
