import React from 'react';
import {
  Navbar, Nav, NavDropdown, Container,
} from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import FileDownload from 'js-file-download';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { getFilenameFromHeader } from '../../utils';

const Header = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const {
    REACT_APP_CONTACT_EMAIL,
  } = process.env;

  const logout = async () => {
    try {
      await axiosPrivate.get('/logout/');
      navigate('/login');
    } catch (err) {
      console.error(err);
    } finally {
      setAuth({});
    }
  };

  const downloadUserLogs = async () => {
    try {
      const response = await axiosPrivate.get('/admin/getUserLog/', {
        responseType: 'blob',
      });
      const content_disposition = response.headers['content-disposition'];
      const filename = getFilenameFromHeader(content_disposition);
      FileDownload(response.data, filename);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" id="header" className="mb-3">
      <Container>
        <Navbar.Brand as={NavLink} to="/">SCOTUSApp</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/about" id="about">About</Nav.Link>
            <Nav.Link href={`mailto:${REACT_APP_CONTACT_EMAIL}`} id="contact">Contact</Nav.Link>
          </Nav>
          <Nav>
            {auth?.user?.authority === 2 && (
              <NavDropdown title="Utilities" id="utilities">
                <NavDropdown.Item onClick={downloadUserLogs}>Download User Logs</NavDropdown.Item>
              </NavDropdown>
            )}
            {auth?.user?.authority >= 0 ? (
              <NavDropdown title={`Signed in as ${auth?.user?.name}`} id="user-nav">
                <NavDropdown.Item as={NavLink} to="/profile/">Edit Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <NavDropdown title="Not signed in" id="no-user-nav">
                <NavDropdown.Item as={NavLink} to="/login/">Login</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/register/">Register</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/forgot-password/">Forgot Password</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
