import React from 'react';
import MUIDataTable from 'mui-datatables';
import { getSMMColumns } from '../../utils.js';

const SMMTable = ({ metric, data }) => {
  const cols = getSMMColumns(metric, data);

  const tableOptions = {
    selectableRows: 'none',
    responsive: 'simple',
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    download: false,
    pagination: false,
  };

  return (
    <MUIDataTable
      className={`smm-table ${metric}-table`}
      title={metric[0].toUpperCase() + metric.slice(1)}
      data={data || []}
      columns={cols}
      options={tableOptions}
    />
  );
};

export default SMMTable;
