import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SourceFilter from './SourceFilter';
import ResultsTable from './ResultsTable';
import SearchForm from './SearchForm';
import { getCriteriaFromParams } from '../../utils';

const Home = () => {
  const axiosPrivate = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const criteria = {
    ...getCriteriaFromParams(searchParams),
  };
  const [results, setResults] = useState({
    set: [],
    resultSources: [],
    total: 0,
  });

  useEffect(() => {
    document.title = 'SCOTUSApp - Search';
  }, []);

  // update search results
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const articleSearch = async () => {
      try {
        const response = await axiosPrivate.post('/approved/search/', {
          signal: controller.signal,
          criteria,
        });

        isMounted && setResults(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    articleSearch();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [searchParams]);

  return (
    <>
      <Container>
        <SearchForm criteria={criteria} />
      </Container>
      <Container fluid className="mt-3">
        <Row>
          <Col xs={12} lg={3}>
            <SourceFilter
              criteria={criteria}
              results={results}
            />
          </Col>
          <Col xs={12} lg={9}>
            <ResultsTable
              results={results}
              criteria={criteria}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
