import React, { useState } from 'react';
import FileDownload from 'js-file-download';
import { Form, Button, Badge } from 'react-bootstrap';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { getFilenameFromHeader } from '../../utils';

const DownloadPanel = ({ criteria }) => {
  const axiosPrivate = useAxiosPrivate();
  const [downloadTxts, setDownloadTxts] = useState(true);
  const initialButtonState = {
    label: 'Download Results',
    disabled: false,
    class: 'primary',
  };
  const [buttonAttrs, setButtonAttrs] = useState(initialButtonState);

  const downloadSearchResults = async () => {
    try {
      setButtonAttrs({
        label: 'Processing...',
        disabled: true,
        class: 'warning',
      });
      const response = await axiosPrivate.post('/approved/downloadResults/', { criteria, downloadTxts }, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100);
          setButtonAttrs({
            ...buttonAttrs,
            label: `Downloading... ${percentCompleted}%`,
          });
        },
      });
      const content_disposition = response.headers['content-disposition'];
      const filename = getFilenameFromHeader(content_disposition);
      FileDownload(response.data, filename);
      setButtonAttrs({
        label: 'Download Completed',
        disabled: false,
        class: 'success',
      });
    } catch (err) {
      setButtonAttrs({
        label: 'Download Failed',
        disabled: false,
        class: 'danger',
      });
    } finally {
      setTimeout(() => {
        setButtonAttrs(initialButtonState);
      }, 10000);
    }
  };

  return (
    <>
      <div className="my-2">
        <Form.Check
          inline
          type="switch"
          id="dl-switch"
          label={<Badge bg="success">{downloadTxts ? 'CSV + TXTs' : 'CSV Only'}</Badge>}
          onChange={() => setDownloadTxts(!downloadTxts)}
          checked={downloadTxts}
        />
        <Button
          className={`btn-${buttonAttrs.class}`}
          disabled={buttonAttrs.disabled}
          onClick={downloadSearchResults}
        >
          {buttonAttrs.label}
        </Button>
      </div>
      { buttonAttrs.disabled && (
        <small className="dl-msg">Large downloads may take several minutes.</small>
      )}
    </>
  );
};

export default DownloadPanel;
