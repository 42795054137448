import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import RequireAuth from './components/auth/RequireAuth';
import PersistLogin from './components/auth/PersistLogin';
import Layout from './components/template/Layout';
import Home from './components/home/Home';
import Unauthorized from './components/template/Unauthorized';
import ApproveNewUser from './components/ApproveNewUser';
import ArticlePage from './components/article-page/ArticlePage';
import ResetPasswordEmailInput from './components/reset-password/ResetPasswordEmailInput';
import ResetPassword from './components/reset-password/ResetPassword';
import Profile from './components/profile/Profile';
import TermsOfUse from './components/TermsOfUse';
import About from './components/About';

const roles = {
  unapproved: 0,
  approved: 1,
  admin: 2,
};

const App = () => (
  <Routes>
    <Route element={<PersistLogin />}>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password/" element={<ResetPasswordEmailInput />} />
        <Route path="/forgot-password/reset/:token" element={<ResetPassword />} />
        <Route path="/404" element={<Unauthorized />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/about/" element={<About />} />
        {/* private routes */}
        <Route element={<RequireAuth minauth={roles.unapproved} />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<RequireAuth minauth={roles.approved} />}>
          <Route path="/" element={<Home />} />
          <Route path="/article/:id" element={<ArticlePage />} />
        </Route>
        <Route element={<RequireAuth minauth={roles.admin} />}>
          <Route path="/approve/:id" element={<ApproveNewUser />} />
        </Route>
        <Route path="*" element={<Unauthorized />} />
      </Route>
    </Route>
  </Routes>
);

export default App;
