import MUIDataTable from 'mui-datatables';
import { useSearchParams, Link } from 'react-router-dom';
import DownloadPanel from './DownloadPanel';

const ResultsTable = ({ results, criteria }) => {
  const [, setSearchParams] = useSearchParams();

  const renderArticleLinks = (value, tableMeta) => {
    const id = tableMeta.rowData[0];
    return (
      <Link to={`/article/${id}/`} className="article-link">
        {value}
      </Link>
    );
  };

  const columns = [
    {
      name: 'article_id',
      label: 'Article ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: renderArticleLinks,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        sort: true,
        customBodyRender: renderArticleLinks,

      },
    },
    {
      name: 'source',
      label: 'Source',
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderArticleLinks,
      },
    },
    {
      name: 'published',
      label: 'Date/Time Published',
      options: {
        filter: false,
        sort: true,
        customBodyRender: renderArticleLinks,
      },
    },
  ];

  const tableOptions = {
    selectableRows: 'none',
    responsive: 'simple',
    // resizableColumns: true,
    print: false,
    filter: false,
    search: false,
    download: false,
    customToolbar: () => <DownloadPanel criteria={criteria} />,
    viewColumns: false,
    jumpToPage: true,
    serverSide: true,
    count: results.total,
    page: criteria.page,
    rowsPerPage: criteria.limit,
    rowsPerPageOptions: [15, 25, 50, 100],
    onChangePage: (currentPage) => {
      setSearchParams({
        ...criteria,
        page: currentPage,
      });
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setSearchParams({
        ...criteria,
        page: 0,
        limit: numberOfRows,
      });
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSearchParams({
        ...criteria,
        page: 0,
        sortBy: changedColumn,
        sortDirection: direction,
      });
    },
  };

  return (
    <MUIDataTable
      className="results-table"
      title={`${results?.total?.toLocaleString() || 0} results`}
      data={results.set}
      columns={columns}
      options={tableOptions}
    />
  );
};

export default ResultsTable;
