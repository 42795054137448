import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button, Form, Row, Col, Stack,
} from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { organizeSources, convertArrayToMultiselect, buildSourceOptions } from '../../utils';
import CreatableInputOnly from './CreatableInputOnly';

const SearchForm = ({ criteria }) => {
  const axiosPrivate = useAxiosPrivate();
  const [allSources, setAllSources] = useState([]);

  const [, setSearchParams] = useSearchParams();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: criteria.title,
      body: criteria.body,
      from: criteria.from,
      to: criteria.to,
      sources: allSources?.length ? buildSourceOptions(criteria.sources, allSources) : convertArrayToMultiselect(criteria.sources),
      ids: convertArrayToMultiselect(criteria.ids),
      keywords: convertArrayToMultiselect(criteria.keywords),
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, actions) => {
      setSearchParams({
        ...criteria,
        ...values,
        sources: values.sources.map((source) => Number(source.value)),
        ids: values.ids.map((id) => Number(id.value)),
        keywords: values.keywords.map((keyword) => keyword.value),
        page: 0,
        filteredSources: [],
      });
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSources = async () => {
      try {
        const response = await axiosPrivate.get('/approved/getAllSources/');
        isMounted && setAllSources(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getSources();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const resetSearch = () => {
    const resetState = {
      title: '',
      body: '',
      keywords: [],
      from: '',
      to: '',
      sources: [],
      ids: [],
      filteredSources: [],
      page: 0,
    };
    setSearchParams(resetState);
  };

  const selectStyle = {
    option: (base) => ({
      ...base,
      color: '#000',
    }),
    container: (base) => ({
      ...base,
      padding: 0,
      height: 'fit-content',
      zIndex: 4,
      color: '#000',
      fontSize: '.875rem',
    }),
    control: (base) => ({
      ...base,
      borderWidth: 0,
      minHeight: 'fit-content',
      height: 'fit-content',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '31px !important',
    }),
    input: (base, state) => ({
      ...base,
      height: '21px !important',
      padding: 0,
    }),
    multiValue: (base) => ({
      ...base,
      padding: 0,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '.875rem',
    }),
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="search-fields mb-1">
        <Col xs={12} md={4} lg={3}>
          <Form.Label htmlFor="title" column="sm">Title</Form.Label>
          <Form.Control
            name="title"
            placeholder="Search term in title..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            disabled={formik.isSubmitting}
            size="sm"
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Form.Label htmlFor="body" column="sm">Article Body</Form.Label>
          <Form.Control
            name="body"
            placeholder="Search term in article body..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.body}
            disabled={formik.isSubmitting}
            size="sm"
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Form.Label htmlFor="from" column="sm">Published From</Form.Label>
          <Form.Control
            type="date"
            name="from"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.from}
            disabled={formik.isSubmitting}
            size="sm"
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Form.Label htmlFor="to" column="sm">Published To</Form.Label>
          <Form.Control
            type="date"
            name="to"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.to}
            disabled={formik.isSubmitting}
            size="sm"
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Form.Label column="sm">Keywords</Form.Label>
          <CreatableInputOnly
            onChange={(selected) => {
              formik.setFieldValue('keywords', selected);
            }}
            placeholder="Delimited by space / tab"
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            value={formik.values.keywords}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Form.Label column="sm">IDs</Form.Label>
          <CreatableInputOnly
            onChange={(selected) => {
              formik.setFieldValue('ids', selected);
            }}
            placeholder="Delimited by space/tab"
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            validate={((value) => /^\d+/.test(value))}
            value={formik.values.ids}
          />
        </Col>
        <Col xs={12} md={9} lg={4}>
          <Form.Label column="sm">Sources</Form.Label>
          <Select
            closeMenuOnSelect={false}
            isMulti
            options={organizeSources(allSources)}
            menuPortalTarget={document.body}
            styles={selectStyle}
            filterOption={createFilter({ ignoreAccents: false })}
            onChange={(selected) => {
              formik.setFieldValue('sources', selected);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.sources}
            maxMenuHeight={400}
            disabled={formik.isSubmitting}
          />
        </Col>
        <Col xs={12} md={3} lg={2} className="pt-3 mt-auto">
          <Stack gap={2}>
            <Button variant="success" type="submit" size="sm" disabled={formik.isSubmitting}>
              Search
            </Button>
            <Button
              variant="info"
              size="sm"
              className="ml-2"
              disabled={formik.isSubmitting}
              onClick={() => {
                resetSearch();
              }}
            >
              Reset Filters
            </Button>
          </Stack>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
