import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomModal from './CustomModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

const DeleteProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [disabled, setDisabled] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const profileDeletion = async () => {
    try {
      setDisabled(true);
      await axiosPrivate.get('/all/deleteProfile/');
      setShowModal(false);
      setAuth({});
      navigate('/login/', { state: { msg: 'Profile has been deleted.' } });
    } catch (err) {
      console.error(err);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      <Button variant="danger" type="button" onClick={() => setShowModal(true)}>
        Delete Account
      </Button>
      <CustomModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        header={<h2>Delete Account</h2>}
        body={<p className="text-center">Are you sure you want to delete your account?</p>}
        footer={[
          <Button key="delete" variant="primary" onClick={profileDeletion} disabled={disabled}>Yes</Button>,
          <Button key="no-delete " variant="secondary" onClick={() => setShowModal(false)} disabled={disabled}>No</Button>,
        ]}
      />
    </>
  );
};

export default DeleteProfile;
