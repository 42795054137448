import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

// Adapted from React-Select documentation: https://react-select.com/creatable
const CreatableInputOnly = ({
  onChange, validate, value, ...rest
}) => {
  const [values, setValues] = useState({
    inputValue: '',
    selectedValues: value || [],
  });

  const createOption = (inputValue) => (
    { label: inputValue, value: inputValue }
  );

  useEffect(() => {
    onChange(values.selectedValues);
  }, [values.selectedValues]);

  useEffect(() => {
    setValues({ inputValue: '', selectedValues: value });
  }, [value]);

  const components = { DropdownIndicator: null };

  const handleChange = (value) => { setValues({ ...values, selectedValues: value }); };

  const styles = {
    container: (provided, state) => ({
      ...provided,
      padding: 0,
      height: 'fit-content',
      zIndex: 4,
      color: '#000',
      fontSize: '.875rem',
    }),
    control: (provided, state) => ({
      ...provided,
      borderWidth: 0,
      minHeight: 'fit-content',
      height: 'fit-content',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '31px !important',
    }),
    input: (provided, state) => ({
      ...provided,
      height: '21px !important',
      padding: 0,
    }),
    multiValue: (base) => ({
      ...base,
      padding: 0,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const onInputChange = (inputValue) => {
    if (!inputValue || !validate || validate(inputValue)) { // function not declared or validation returns True
      setValues({ ...values, inputValue }); // all conditions met, set input value
    }
  };

  const onKeyDown = (event) => {
    switch (event.key) {
      case ' ':
      case 'Tab':
        event.preventDefault();
        const alreadyEntered = values.selectedValues?.find((option) => option.label === values.inputValue && option.value === values.inputValue);
        if (values.inputValue && !alreadyEntered) {
          setValues({
            inputValue: '',
            selectedValues: [
              ...values.selectedValues,
              createOption(values.inputValue),
            ],
          });
        }
    }
  };

  return (
    <CreatableSelect
      {...rest}
      value={value}
      components={components}
      inputValue={values.inputValue}
      styles={styles}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
    />
  );
};

export default CreatableInputOnly;
