import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Button, Tabs, Tab, Card,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ImageTab from './ImageTab';
import SMMTable from './SMMTable';
import SimilarsTable from './SimilarsTable';
import SourceData from './SourceData';

const ArticlePage = () => {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [articleDetails, setArticleDetails] = useState({});
  const [imageDetails, setImageDetails] = useState([]);
  const [tab, setTab] = useState('source-data');
  const [disableIrButton, setDisableIrButton] = useState(false);
  const [disableIcButton, setDisableIcButton] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    document.title = `SCOTUSApp - Article ${id}`;

    const getArticleDetails = async () => {
      try {
        const response = await axiosPrivate.get(`/approved/getArticleDetails/${id}/`, {
          signal: controller.signal,
        });
        isMounted && setArticleDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getArticleDetails();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getImageDetails = async () => {
      try {
        const response = await axiosPrivate.get(`/approved/getArticleImages/${id}/`, {
          signal: controller.signal,
        });
        isMounted && setImageDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getImageDetails();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    setTab('source-data');
  }, [id]);

  const MarkButton = ({
    field, unmarkedContent, markedContent, setDisabled, ...props
  }) => {
    const markArticle = async () => {
      try {
        setDisabled(true);
        await axiosPrivate.post('/approved/markArticle/', {
          article_id: id,
          field,
        });
        const markedField = {};
        markedField[field] = true;
        setArticleDetails({
          ...articleDetails,
          ...markedField,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setDisabled(false);
      }
    };

    const articleLoaded = Object.keys(articleDetails).length;
    if (!articleLoaded) {
      return null;
    }

    return articleDetails[field] ? markedContent : (
      <Button {...props} onClick={markArticle}>
        { unmarkedContent }
      </Button>
    );
  };

  const displayValue = (value) => ([null, undefined].includes(value) ? 'N/A' : value);

  return (
    <Container className="my-3">
      <Row>
        <Col xs={12} lg={3}>
          <Card className="bg-light text-dark" id="details">
            <Card.Header>
              <Card.Title>Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Article ID</Card.Subtitle>
                  <Card.Text>{articleDetails?.article_id}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Alt ID</Card.Subtitle>
                  <Card.Text>{articleDetails?.alt_id}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Author</Card.Subtitle>
                  <Card.Text>{displayValue(articleDetails?.author)}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Source</Card.Subtitle>
                  <Card.Text>{articleDetails?.source?.domain}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Publication Date</Card.Subtitle>
                  <Card.Text>{articleDetails?.published}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Relevancy Score</Card.Subtitle>
                  <Card.Text>{articleDetails?.relevancy_score}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Sentiment Score</Card.Subtitle>
                  <Card.Text>{displayValue(articleDetails?.sentiment_score)}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Sentiment Magnitude</Card.Subtitle>
                  <Card.Text>{displayValue(articleDetails?.sentiment_magnitude)}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>URL</Card.Subtitle>
                  <Card.Text>
                    {articleDetails?.url && (
                      <a className="text-dark link" href={articleDetails?.url || ''} target="_blank" rel="noopener noreferrer">
                        {articleDetails?.url}
                      </a>
                    )}
                  </Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <MarkButton
                    field="marked_irrelevant"
                    variant="primary"
                    unmarkedContent="Mark as Irrelevant"
                    markedContent={<span className="irrelevantArticle">Marked as irrelevant to SCOTUS.</span>}
                    disabled={disableIrButton}
                    setDisabled={setDisableIrButton}
                  />
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <MarkButton
                    field="marked_incomplete"
                    variant="secondary"
                    unmarkedContent="Mark as Incomplete"
                    markedContent={<span className="incompleteArticle">Marked as having incomplete text.</span>}
                    disabled={disableIcButton}
                    setDisabled={setDisableIcButton}
                  />
                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={9}>
          <Card className="bg-light text-dark h-100" id="article">
            <Card.Header>
              <Card.Title id="article-title">{articleDetails?.title}</Card.Title>
            </Card.Header>
            <Card.Header className="my-1">
              <Card.Subtitle className="">
                {articleDetails?.charnum}
                {' '}
                characters
              </Card.Subtitle>
              <Card.Subtitle className="mt-2" id="keywords">Keywords</Card.Subtitle>
              <Card.Text>
                <small>{articleDetails?.keywords?.join(', ')}</small>
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Card.Text id="article-text">{articleDetails?.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Card className="bg-light text-dark" id="tabs">
          <Tabs
            id="tabs"
            activeKey={tab}
            onSelect={(key) => setTab(key)}
          >
            <Tab eventKey="source-data" title="Source Data">
              <SourceData data={articleDetails?.source} />
            </Tab>
            <Tab eventKey="smm" title="Social Media Metrics">
              {Object.keys(articleDetails?.social_media_metrics || []).map((metric) => (
                <Row key={metric} className="mt-3">
                  <Col xs={12}>
                    <SMMTable
                      metric={metric}
                      data={articleDetails?.social_media_metrics[metric] || []}
                    />
                  </Col>
                </Row>
              ))}
            </Tab>
            <Tab eventKey="images" title={`Images (${imageDetails?.length})`}>
              <ImageTab images={imageDetails} />
            </Tab>
            <Tab eventKey="similar-articles" title="Similar Articles">
              <Row className="mt-3">
                <Col sm={12}>
                  <SimilarsTable
                    className="sim-table before-pub"
                    title="Before Publication"
                    data={articleDetails?.similar_articles?.filter((item) => item.afterPublication === 0) || []}
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col sm={12}>
                  <SimilarsTable
                    className="sim-table after-pub"
                    title="After Publication"
                    data={articleDetails?.similar_articles?.filter((item) => item.afterPublication === 1) || []}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card>
      </Row>
    </Container>
  );
};

export default ArticlePage;
