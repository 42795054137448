import React, { Modal } from 'react-bootstrap';

const CustomModal = ({
  show, handleClose, header, body, footer, ...props
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      {header}
    </Modal.Header>
    <Modal.Body>
      {body}
    </Modal.Body>
    <Modal.Footer>
      {footer}
    </Modal.Footer>
  </Modal>
);

export default CustomModal;
