import React, { useState } from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';

const ImageTab = ({ images }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Row className="py-2">
      {images?.length
        ? ([
          <Col xs={12} lg={7} key="img-carousel">
            <Carousel
              className="bg-dark"
              variant="light"
              slide={false}
              controls={images?.length > 1}
              indicators={images?.length > 1}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {images?.map((img) => (
                <Carousel.Item key={img.id}>
                  <img
                    className="article-image"
                    src={img.image !== null
                        ? `data:image/png;base64,${img.image}`
                        : '/noimage.png'}
                    alt="Article Image"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>,
          <Col key="image-entities" sm={12} lg={4}>
            <h2 className="mb-3 text-dark">Image Entities</h2>
            { images[index]?.entities?.length
              ? images[index].entities.map(({ entity, score }) => (
                <div key={entity} className="mb-2">
                  {entity}
                  :
                  {' '}
                  {score}
                </div>
              ))
              : 'None'}
          </Col>,
        ]) : (
          <Col sm={12} lg={5}>
            No Images
          </Col>
        )}
    </Row>
  );
};

export default ImageTab;
