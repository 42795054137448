import React, { useEffect, useState } from 'react';
import {
  Container, Button, Form, Row, Col, Alert,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth.js';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';
import { isNumber } from '../utils.js';

const ApproveNewUser = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const { id } = useParams();
  const [statusMsg, setStatusMsg] = useState({});

  const [newUser, setNewUser] = useState({
    id,
    name: '',
    email: '',
    joined: '',
    bio: '',
    role: undefined,
  });

  const validate = (values) => {
    const errors = {};
    const MAX_MESSAGE_LENGTH = 1000;
    const authCodes = ['0', '1', '2'];

    if (auth?.user.user_id === Number(id)) errors.role = 'Cannot change your own privileges.';
    else if (newUser.role === '2') errors.role = 'Cannot change another admin\'s privileges.';
    else if (!authCodes?.includes(values.role)) errors.role = 'Invalid option.';
    if (values?.message?.length > MAX_MESSAGE_LENGTH) errors.message = 'Exceeds maximum length.';

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      role: String(newUser.role),
      message: '',
    },
    validate,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, actions) => {
      try {
        const response = await axiosPrivate.put(`/admin/updateRole/${id}/${values.role}/`, {
          message: values.message,
        });
        setNewUser({ ...newUser, role: String(values.role) });
        setStatusMsg({ variant: 'success', msg: response.data });
      } catch (err) {
        console.error(err);
        const errmsg = err.response ? err.response.data : err.message;
        setStatusMsg({ variant: 'danger', msg: errmsg });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    document.title = 'SCOTUSApp - Manage User';
    let isMounted = true;
    const controller = new AbortController();

    const getProfile = async () => {
      try {
        const response = await axiosPrivate.get(`/admin/getProfile/${id}/`, {
          signal: controller.signal,
        });
        const { data } = response;
        isMounted && setNewUser({ ...data, role: String(data.role) });
      } catch (err) {
        console.error(err);
      }
    };

    getProfile();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!isNumber(id)) setStatusMsg({ variant: 'danger', msg: 'Invalid user.' });
  }, [id]);

  useEffect(() => {
    if (newUser.role) formik.setFieldValue('role', newUser.role);
  }, [newUser]);

  const disableForm = !isNumber(id) || newUser.role === '2' || auth?.user?.user_id === id;

  return (
    <Container>
      <div className="bg-light mt-3 p-5 text-dark rounded">
        <Container>
          <h2 className="text-center">Approve User</h2>
          <p className="text-center my-4">Admins can manage a user's security role on this screen.</p>
          <Row>
            <Col className="text-center" sm={12} md={6} lg={6}>
              <p>
                Name:
                {newUser.name}
              </p>
              <p>
                Email:
                <a className="text-dark link" href={`mailto:${newUser.email}`}>{newUser.email}</a>
              </p>
              <p>
                Joined:
                {newUser.joined}
              </p>
              <p>{newUser.bio}</p>
            </Col>
            <Col sm={12} md={6} lg={5}>
              {statusMsg?.variant && statusMsg?.msg
                ? (<Alert key="err" variant={statusMsg.variant}>{statusMsg.msg}</Alert>)
                : null }
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="role_field">
                  <Form.Label>User Role</Form.Label>
                  {formik.errors.role
                    ? (<Alert key="role-err" variant="danger">{formik.errors.role}</Alert>) : null}
                  <Form.Select
                    name="role"
                    aria-label="User role selection"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                    disabled={disableForm}
                  >
                    <option value="0">Unapproved</option>
                    <option value="1">Approved</option>
                    <option value="2">Administrator</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="message_field">
                  <Form.Label>Message to User</Form.Label>
                  {formik.touched.message && formik.errors.message
                    ? (<Alert key="message-err" variant="danger">{formik.errors.message}</Alert>) : null}
                  <Form.Control
                    as="textarea" rows={5} name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    htmlSize={1000}
                    disabled={disableForm}
                  />
                  <Form.Text id="messageHelpBlock" muted>
                    This message will be sent to the user upon submission (optional, leave blank if no message desired).
                                    </Form.Text>
                </Form.Group>
                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    disabled={formik.isSubmitting || disableForm}
                  >
                                      Update User
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default ApproveNewUser;
